import React from 'react';
import { step2 } from './ProcessStep2.module.scss';

const ProcessStep2: React.FC = () => {
  return (
    <div className="d-flex flex-direction-row p-2" data-testid="step2-container">
      <div className="row">
        <div className="benefits-list col-lg-5 col-md-7 p-2" data-testid="step2-list">
          <h2>Step 2</h2>
          <p>
            The team begins working. Our cloud experts create the right environment for your application focusing on
            best performance for the best price. Our developers then get to coding, creating your application and giving
            you updates through out the full process.
          </p>
        </div>
        <div className="col-lg-7 col-md-5">
          <img
            src="../images/step2.jpg"
            data-testid="step2-image"
            alt="Four people at computer"
            className={`${step2} img-fluid`}
          />
        </div>
      </div>
    </div>
  );
};

export default ProcessStep2;
