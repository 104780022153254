import React from 'react';
import { steps } from './ProcessSteps.module.scss';

const ProcessStep1: React.FC = () => {
  return (
    <div className="d-flex flex-direction-row p-2" data-testid="step1-container">
      <div className="row d-flex flex-md-row flex-column-reverse">
        <div className="col-lg-7 col-md-4">
          <img
            src="../../images/step1.jpg"
            alt="Countertop with many people and their computers."
            data-testid="step1-image"
            className={`${steps} img-fluid`}
          />
        </div>
        <div className="step1-list col-lg-5  col-md-8 p-2" data-testid="step1-list">
          <h2>Step 1</h2>
          <p>
            After you get into contact with us, we will have you meet up with our cloud and developers. You and them
            will communicate what you want to create. We then get together and create an estimate that we send to you.
            We will prioritize keeping it affordable for the hours worked and streamline it to make it easier for us
            all.{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProcessStep1;
