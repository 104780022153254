import React from 'react';
import { steps } from './ProcessSteps.module.scss';

const ProcessStep3: React.FC = () => {
  return (
    <div className="d-flex flex-direction-row p-2" data-testid="step3-container">
      <div className="row d-flex flex-md-row flex-column-reverse">
        <div className="col-lg-7 col-md-5 p-2">
          <img
            src="../../images/step3.jpg"
            data-testid="step3-image"
            alt="Woman and Man Discussing"
            className={`${steps} img-fluid`}
          />
        </div>
        <div className="step3-list col-lg-5  col-md-7 p-2" data-testid="step3-list">
          <h2>Step 3</h2>
          <p>
            The step where it all comes together. Our developers show you the progress and final product and once we
            make final adjustments to your requests, we then connect our cloud and application to get your online
            presence live.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProcessStep3;
