import * as React from 'react';
import { Helmet } from 'react-helmet';
import ContactUsButton from '../compontents/ContactUsButton/ContactUsButton';
import Layout from '../compontents/Layout/Layout';
import ProcessStep1 from '../compontents/ProcessPage/ProcessStep1';
import ProcessStep2 from '../compontents/ProcessPage/ProcessStep2';
import ProcessStep3 from '../compontents/ProcessPage/ProcessStep3';

type ProcessPageProps = {
  location: any;
};

const ProcessPage: React.FC<ProcessPageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Process - How we streamline IT!</title>
      </Helmet>
      <div className="process-container" data-testid="process-container"></div>
      <ProcessStep1></ProcessStep1>
      <ProcessStep2></ProcessStep2>
      <ProcessStep3></ProcessStep3>
      <div className="get_in_touch_section" data-testid="get-in-touch-section">
        <div className="get_in_touch_text p-2" data-testid="get-in-touch-text">
          Contact Us now to get your presence online or build your application.
        </div>
        <div className="mt-sm-4" data-testid="get-in-touch-link">
          <ContactUsButton />
        </div>
      </div>
    </Layout>
  );
};

export default ProcessPage;
